.navbar .navbar-nav {
    min-height: 1rem;
}

// Align logo baseline with nav-items
.navbar-brand {
    position: relative;
    top: 0.125rem;
}

.navbar-logo {
    height: 32px;
    width: auto;
}

.navbar-logo-lg {
    height: 32px;
}

.navbar-brand-autodark {
    .navbar-logo {
        color: $navbar-dark-brand-color;
    }
}
