turbo-frame {
    display: block;
}

.form-group {
    margin-bottom: 1rem;
}

.compound > .form-group:last-child,
.accordion-body > .form-group:last-child,
.card-body > .form-group:last-child {
    margin-bottom: 0;
}

.row-cols-inline {
    > * > .form-group {
        margin-bottom: 0;
    }
}

.hidden {
    display: none !important;
}

.form-control[size] {
    .input-group > & {
        flex-grow: 0;
    }
}

.form-select-sm {
    padding-right: 1.5rem;
    background-position: right 0.25rem center;
}

.form-check-input-sm {
    width: 1rem;
    height: 1rem;
    background-size: 0.875rem 0.875rem;
}

.input-group-search {
    max-width: 15em;
}

.form-selectgroup-input:disabled + .form-selectgroup-label {
    pointer-events: none;
    opacity: 0.7;
}

.form-selectgroup-full {
    display: flex;

    .form-selectgroup-item {
        flex: 1 0 0%;
    }
}

.col-form-label {
    &:not([class*='col-']) {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: $form-label-margin-bottom;
    }
}
