.text-lg {
    font-size: 1.125rem;
}

.text-xl {
    font-size: 1.25rem;
}

.text-2xl {
    font-size: 1.5rem;
}

.text-bg-black {
    background: $brand-black;
    color: color-contrast($brand-black);
}

.text-justify {
    text-align: justify;
}

.text-transform-none {
    text-transform: none !important;
}
