.nav-breadcrumb {
    background: $bg-surface;
    padding: 0.75rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

li.breadcrumb-item {
    font-size: 0.875rem;

    a,
    span {
        color: rgba(0, 0, 0, 0.65);
    }

    a:hover {
        color: rgba(0, 0, 0, 0.85);
    }

    &.active {
        font-weight: 600;
    }
}
