.card {
    .card.tab-pane {
        --#{$prefix}card-border-width: 0;
    }

    &.tab-pane .card-table-list {
        --#{$prefix}card-border-width: 0;
    }
}

.card-with-progress {
    border-top-left-radius: $progress-border-radius;
    border-top-right-radius: $progress-border-radius;
}

.card-plain {
    --#{$prefix}card-box-shadow: none;
    border-width: 0;
}

.card-header-light,
.card-header {
    border-bottom: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color);
}

.card-footer,
.card-body + .card-header {
    border-top: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color);
}

@include media-breakpoint-up(md) {
    .card-md {
        --#{$prefix}card-cap-padding-x: 2.5rem;
        --#{$prefix}card-spacer-x: 2.5rem;
    }
}

.card.fade {
    transition: $transition-fade;
}
