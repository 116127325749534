.icon {
    --#{$prefix}icon-size: #{$icon-size};
    vertical-align: middle;
}

.btn .icon {
    --#{$prefix}btn-padding-x: 0;
}

.btn-with-icon .icon {
    --#{$prefix}btn-padding-x: 0.5rem;
}

.icon-logo {
    color: $secondary;
}
