@import 'tom-select/src/scss/tom-select.bootstrap5';

.plugin-clear_button .clear-button {
    height: 100%;
}

.disabled .ts-control {
    opacity: 1;
    color: $form-select-disabled-color;
}

.form-select-sm.ts-wrapper {
    &.single {
        --ts-pr-caret: 1.25rem;
    }

    .ts-control {
        padding-top: $form-select-padding-y-sm;
        padding-bottom: $form-select-padding-y-sm !important;
        padding-left: $form-select-padding-x-sm;
        padding-right: 2.5rem;
    }
}
