@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @for $i from 1 through $grid-columns {
            .grid-cols#{$infix}-#{$i} {
                grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
            }
        }
    }
}

.row-cards {
    &.gx-0 {
        --#{$prefix}gutter-x: 0;
    }
}
