@import 'bootstrap/scss/functions';

// Fonts
$font-family-base: 'Nunito Sans', sans-serif;

// Colors
$body-bg: #f6f8fb;
$primary: #cb5067;
$secondary: #323265;
$brand-black: #1d1d1f;

$male-color: #4a90e2;
$female-color: #f5515f;

// Configuration
$enable-dark-mode: true;
$enable-rounded: true;
$enable-cssgrid: true;

// Types
$lead-font-size: 1.25rem;

// Buttons
$btn-border-radius-lg: 0.25rem; // Same as btn

// Cards
$card-border-color: $brand-black;
$card-border-radius: 0.75rem;

// Icons
$icon-size: 1.125em;

// Shadows
$box-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);

@import 'bootstrap/scss/functions';
@import '@tabler/core/src/scss/variables';
@import '@tabler/core/src/scss/variables-dark';
@import '@tabler/core/src/scss/utilities';
@import '@tabler/core/src/scss/mixins';

$utilities: map-merge(
    $utilities,
    (
        'rotate': (
            property: transform,
            class: rotate,
            responsive: true,
            values: (
                0: rotate(0),
                90: rotate(90deg),
                180: rotate(180deg),
                270: rotate(270deg),
            ),
        ),
    )
);

$spacers: map-merge(
    $spacers,
    (
        9: 10rem,
        10: 12rem,
    )
);

@import '@tabler/core/src/scss/bootstrap-config';
@import '@tabler/core/src/scss/bootstrap-override';
