.btn-sm,
.btn-group-sm > .btn {
    --#{$prefix}btn-icon-size: 1em;
}

.btn-icon-sm,
.btn-group-icon-sm > .btn,
.btn-group-icon-sm > .btn-group > .btn {
    --#{$prefix}btn-icon-size: 0.875em;
    --#{$prefix}btn-padding-x: 0.5rem;
    --#{$prefix}btn-padding-y: 0.25rem;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle).btn-dropdown {
    @include border-end-radius($btn-border-radius);
}
