.form-collection.accordion > .accordion-item {
    > .accordion-header {
        padding: $card-cap-padding-y * 0.5 $card-cap-padding-x * 0.75;
        display: flex;
        align-items: center;

        &:not(.collapsed) {
            border-bottom: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
            &,
            .col-form-label {
                font-weight: bold;
            }
        }
    }
}
