.step-with-icon {
    --#{$prefix}steps-dot-size: var(--step-icon-size);
    position: relative;

    &::before {
        background: white !important;
    }

    .step-icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
}

.vertical-stepper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .step {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: calc(var(--step-icon-size) / 2);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .step-line {
        width: 2px;
        height: calc(var(--step-icon-size) / 2);
        background-color: $steps-inactive-color;
        position: absolute;
        top: 100%;
    }
}
