.page-wrapper,
#flash-alerts,
.page-header,
.page-header-wrapper {
    & > .alert,
    & > turbo-frame > .alert {
        --#{$prefix}alert-border-radius: 0;
        --#{$prefix}alert-margin-bottom: 0;
    }
}
